import { render, staticRenderFns } from "./Sachse.vue?vue&type=template&id=53de2541&"
import script from "./Sachse.vue?vue&type=script&lang=ts&"
export * from "./Sachse.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/uSacGCCn/0/ucarok/mobileservices-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('53de2541')) {
      api.createRecord('53de2541', component.options)
    } else {
      api.reload('53de2541', component.options)
    }
    module.hot.accept("./Sachse.vue?vue&type=template&id=53de2541&", function () {
      api.rerender('53de2541', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/cities/Sachse.vue"
export default component.exports